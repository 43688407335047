.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    z-index: 0;
}

.publicContainer {
    overflow: scroll;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    background-color: var(--back);
}

.scrollSnap {
    scroll-snap-type: y mandatory;
}