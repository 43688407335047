@import './screens/screen.module.css';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pc_blocker {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 999;
  background-color: var(--green);
  color: antiquewhite;
  font-size: 25px;
  text-align: center;
  padding-top: 300px;
  font-family: var(--default-font);
}








.heart {
  width: 20vw;
  margin: auto;
  margin-top: 10vh;
}

.auth_container {
  margin: auto;
  border: 1px solid #7b7a7a;
  border-radius: 20px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80vw;
  margin-top: 2vh;
}

.alert-danger {
  color: rgb(140, 0, 0);
}

.form-group {
  padding: 0;
  position: relative;
}

.form-group input {
  width: 60vw;
  float: left;
  margin: 2vw;
  padding: 2vw;
  padding-right: 12vw;
  border: 1px solid gray;
  height: 10vw;
  transition: all 0.1s;
}

.form-group input:-webkit-autofill,
.form-group input:-webkit-autofill:hover,
.form-group input:-webkit-autofill:focus,
.form-group input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #17173c inset !important;
}

.form-group input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.form-group span {
  width: 9vw;
  height: 9vw;
  padding-right: 1vw;
  position: absolute;
  right: 2.5vw;
  top: 2.8vw;
  transition: all 0.1s;
  cursor: pointer;
}

a {
  text-decoration: underline;
}

.modal {
  height: 101%;
  width: 101%;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 1;
}

.mActive {
  opacity: 1;
  pointer-events: all;
}

.modalContent {
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  transform: scale(0.5);
  transition: 0.4s all;
  max-width: 1000px;
  width: 80vw;
  height: fit-content;
  max-height: 80vh;
  border: 1px solid #121212;
}

.mcActive {
  transform: scale(1);
}

@media (min-width: 900px) {
  .pc_blocker {
    display: block;
  }
  .auth_container {
    display: none;
  }
}

